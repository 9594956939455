import axios from 'axios'
import { _throw } from "../../../utils/error-service"
import { API } from "../../../constants/system"
import { toJS } from 'mobx'

export const presetActions = self => ({

  update(preset) {
    self.set('id', preset.id)
    self.set('title', preset.title)
    self.set('cover', preset.cover)
    self.set('subscribersOnly', preset.subscribersOnly)
    self.set('category', preset.category)
    self.set('loras', preset.loras)
  },
  set(key, value) {
    self[key] = value
  }
})