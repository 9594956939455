export const ENV = process.env.REACT_APP_ENV
export const API = process.env.REACT_APP_API_URL
export const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID
export const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY
export const APPLE_CLIENT_ID = process.env.APPLE_CLIENT_ID
export const APPLE_REDIRECT_URI = process.env.APPLE_REDIRECT_URI
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
export const STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
export const AIRTABLE_ACCESS_TOKEN = process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN
