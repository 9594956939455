import { Box, Typography } from '@mui/material'
import React from 'react'

const Badge = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: "2px",
        display: 'inline-block'
      }}
    >
      <Typography
        sx={{
          color: "#000",
          fontSize: "8px",
          fontWeight: "bold",
          lineHeight: "10px"
        }}
      >{props.children}</Typography>
    </Box>
  )
}

export default Badge