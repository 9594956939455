import axios from "axios"
import { _throw } from "../utils/error-service"
import { API } from '../constants/system'

export const api_get_presets= async (token) => {
  try {
    const res = await axios.get(`${API}/presets`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_custom_presets= async (token) => {
  try {
    const res = await axios.get(`${API}/my-presets`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_make_preset_public = async (token, data) => {
  try {
    const res = await axios.post(`${API}/preset/make-public`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_save_preset_to_user = async (token, data) => {
  try {
    const res = await axios.post(`${API}/preset/save-to-user`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_make_preset_mine = async (token, data) => {
  try {
    const res = await axios.post(`${API}/preset/make-mine`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

