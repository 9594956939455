import { types } from 'mobx-state-tree'
import GenerateImageStore from '../stores/generateImage/generateImageStore'
import AccountStore from '../stores/accountStore/accountStore'
import AssetFactory from '../factories/assets/assetFactory'
import PresetsFactory from '../factories/presets/presetsFactory'
import ProductsFactory from '../factories/products/productsFactory'
import JobsFactory from '../factories/jobs/jobsFactory'
import GuiStore from '../stores/guiStore/guiStore'
import QueryStore from '../stores/queryStore/queryStore'
import SettingsStore from '../stores/settingsStore/settingsStore'
import SubscriptionStore from '../stores/subscriptionStore/subscriptionStore'


const Store = types
  .model({
    // stores
    generateImageStore: types.optional(GenerateImageStore, {}),
    accountStore: types.optional(AccountStore, {}),
    guiStore: types.optional(GuiStore, {}),
    queryStore: types.optional(QueryStore, {}),
    settingsStore: types.optional(SettingsStore, {}),
    subscriptionStore: types.optional(SubscriptionStore, {}),

    // factories
    assetsFactory: types.optional(AssetFactory, {}),
    presetsFactory: types.optional(PresetsFactory, {}),
    jobsFactory: types.optional(JobsFactory, {}),
    productsFactory: types.optional(ProductsFactory, {}),
    hydrated: false,

    loadingScreen: true,
    hash: ""
  })
  .views(self => ({

  }))
  .actions(self => ({
    reset() {
      self.set('generateImageStore', {})
      self.set('accountStore', {})
      self.set('guiStore', {})
    },
    initializeStudio() {
      try {
        
        self.generateImageStore.set('intervalId', null)
        self.generateImageStore.checkJobsStatusAndUpdate()
        self.generateImageStore.getMyJobs()
        self.guiStore.closePaywallModal()

        // check if enterprise
        // if (self.accountStore.isEnterprise) {
        self.generateImageStore.getCustomPresets()
        // }
      } catch (err) {
        console.log(err)
      }
    },
    set(key, value) {
      self[key] = value
    }
  }))

export default Store
