import { useEffect } from 'react';
import './App.css';
import './mui_overwrites.css'
import routes from './router';
import { useRoutes } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './theme/theme';

import { Provider } from 'mobx-react'

import { persist } from 'mst-persist'
import localForage from 'localforage'
import Store from './context/root/Store' 
import { useAuth0 } from "@auth0/auth0-react";
import { API } from './constants/system';
import smoothscroll from 'smoothscroll-polyfill';
import LoaderWrapper from './components/Loader/LoaderWrapper';

import { _init_tracking_services } from './services/event-track-service'
import useQuery from './hooks/useQuery';
import { Toaster } from 'react-hot-toast';
import ErrorInfo from './components/ErrorInfo/ErrorInfo';
import IntercomMessenger from './components/IntercomMessenger/IntercomMessenger';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2d3549d748a0189cab8772800bea0fd9@o4507485728407552.ingest.de.sentry.io/4507485733716048",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

 const store = Store.create({})

  const App = () => {

    const content = useRoutes(routes);
    const { error, user, isAuthenticated, getAccessTokenSilently, isLoading  } = useAuth0();
    
    useEffect(() => {

      store.set('loadingScreen', true)
      smoothscroll.polyfill();
      const mainKey = `relum-studio-${process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA}`
      persist(mainKey, store, {
        storage: localForage,
        blacklist: ['guiStore', 'loadingScreen'],
        jsonify: false,
      }).then(res => {
        
        localForage.keys()
          .then(keys => {
            if (keys.length > 1) {
              keys.map((key) => {
                if (mainKey !== key) {
                  localForage.removeItem(key)
                }
              })
            }
            store.set('hash', mainKey)
          })


        setTimeout(() => {
          store.accountStore.getUser()
        }, 3500)
        store.set('hydrated', true)
      })
    }, [])

    const getAuth0AccessToken = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: API,
            scope: 'read:users',
          },
        });


        store.accountStore.setAuth0AccessToken(token);
      } catch (e) {
        console.log(e);
      }
    };

    useEffect(() => {
      if (isAuthenticated && store.hydrated) {
        (async () => {
          store.accountStore.setUser(user);
          await getAuth0AccessToken(); 
          store.set('loadingScreen', false)
          store.accountStore.getPackages(store.queryStore.currentPrice)
          
          // store.accountStore.getCheckoutSession(store.queryStore.currentPrice)
          // IF PAYWALL IS ENABLED
          // if (subStatusPromise.data.data.active === false)
          //   store.accountStore.getCheckoutSession(store.queryStore.currentPrice)
          // else 
          //   store.accountStore.getPackages()

          // await store.
          
          store.accountStore.checkIfMergeAccounts()
          store.accountStore.getUser();
          store.generateImageStore.getPresets();
          store.accountStore.getMyProducts()
          store.initializeStudio()
          _init_tracking_services()


          await store.subscriptionStore.paywall()
        })();
      }
    }, [isAuthenticated, store.hydrated]);

    if (error) {
      return <ErrorInfo />
    }

  

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LoaderWrapper>
          <Toaster position='top-right' />
          <IntercomMessenger />
          <CssBaseline />
          {content}
        </LoaderWrapper>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
