import { toJS } from "mobx"
import { getRoot } from "mobx-state-tree"


export const productViews = self => ({
  get accountStore() {
    return getRoot(self).accountStore
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  //
  get shouldGuessType() {
    return (
      (self.productType === "product" && self.tempType === "product") ||
      (self.guessedType && self.productType != self.guessedType)
    )
  },
})