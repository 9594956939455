import { Box, Typography } from '@mui/material'
import React from 'react'

const Credits = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: "center" }}>

      <Typography>
        Products: {props.allProducts}/{props.allProducts + props.productsLeft}
      </Typography>
      <Typography sx={{ marginLeft: "20px" }}>
        Generations: {props.tokens} left
      </Typography>
      <Typography sx={{ marginLeft: "20px", opacity: .2, fontSize: "10px" }}>
        ADMIN ONLY VIEW
      </Typography>
      {/* <Typography>
        Different scenes: {props.allProducts}/{props.allProducts + props.productsLeft}
      </Typography> */}

    </Box>
      
  )
}

export default Credits