import find from 'lodash/find'
import { toJS } from 'mobx'
import { _add_update_objects } from '../../../services/factories-service'

export const presetsFactoryActions = self => ({
  addUpdatePresets(presets) {
    return _add_update_objects(presets, self.presets, self.parsePreset)
  },
  addUpdatePreset(p) {
    const ids = self.addUpdatePresets([p])
    return ids[0]
  },
  parsePreset(p) {
    return ({
      ...p,
      cover: p.cover?.id ? self.assetsFactory.addUpdateAsset(p.cover) : null,
    })
  },
})