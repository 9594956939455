import { types } from 'mobx-state-tree'
import * as views from './accountStoreStoreViews'
import * as actions from './accountStoreStoreActions'
import Product from '../../models/product/Product'
import Plan from '../../models/plan/Plan'

const AccountStore = types
  .model('accountStore', {
    auth0Id: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    emailVerified: types.maybeNull(types.boolean),
    pictureUrl: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    auth0AccessToken: types.maybeNull(types.string),
    stripeCustomerId: types.maybeNull(types.string),
    hasActiveStripeSubsciption: types.maybeNull(types.boolean),
    isTrial: types.maybeNull(types.boolean),
    userId: types.maybeNull(types.number),
    intercomHash: types.maybeNull(types.string),

    currentPlan: types.maybeNull(Plan),
    
    checkoutSessionUrl: types.maybeNull(types.string),
    clientSecretStripe: types.maybeNull(types.string),
    pricingTableClientSecret: types.maybeNull(types.string),
    upgradePrice: types.maybeNull(types.string),
    plans: types.maybeNull(
      types.optional(types.array(Plan), [])
    ),

    tokens: types.maybeNull(types.number),


    // credits
    tokensLeft: types.maybeNull(types.number),
    productsLeft: types.maybeNull(types.number),
    scenesLeft: types.maybeNull(types.number),

    // my products
    products: types.optional(
      types.array(types.reference(Product)), []
    ),

    // for account linking
    duplicates: types.frozen(),

    loadingCheckoutSession: false,

    plan: types.optional(types.string, "DEFAULT")
  })
  .views(views.accountStoreViews)
  .actions(actions.accountStoreActions)

export default AccountStore