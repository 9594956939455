import { types } from 'mobx-state-tree'
import * as views from './generateImageStoreViews'
import * as actions from './generateImageStoreActions'
import Asset from '../../models/asset/Asset'
import Preset from '../../models/preset/Preset'
import Job from '../../models/job/Job'
import Product from '../../models/product/Product'

const GenerateImageStore = types
  .model('generateImageStore', {
    allCategories: types.maybeNull(types.number),
    imageUrl: types.maybeNull(types.string),
    userEmail: types.maybeNull(types.string),
    userPrompt: types.maybeNull(types.string),
    userPromptNegative: types.maybeNull(types.string),
    userPromptSeed: types.maybeNull(types.string),
    userPromptSteps: types.maybeNull(types.string),
    productType: types.maybeNull(types.string),
    customScene: types.maybeNull(types.boolean),

    // uploading reference images
    isReferenceImageUploading: types.maybeNull(types.boolean),
    selectedReferenceImage: types.maybeNull(
      types.reference(Asset)
    ),

    // main image upload (product)
    isImageUploading: types.maybeNull(types.boolean),
    selectedImage: types.maybeNull(
      types.reference(Asset)
    ),

    // presets
    selectedIndex: types.maybeNull(types.number),
    selectedPreset: types.maybeNull(types.reference(Preset)),

    // products
    selectedProduct: types.maybeNull(
      types.reference(Product)
    ),

    presets: types.maybeNull(
      types.array(types.reference(Preset)), []
    ),
    customPresets: types.maybeNull(
      types.array(types.reference(Preset)), []
    ),  
    jobs: types.maybeNull(
      types.array(types.reference(Job)), []
    ),
    runningJobs: types.maybeNull(
      types.array(types.reference(Job)), []
    ),
    fakeJobs: types.optional(
      types.array(Job), []
    ),
    intervalId: types.maybeNull(types.number),
    uploadProgress: types.maybeNull(types.number),
    selectedImageError: types.maybeNull(types.string),
    selectedPresetError: types.maybeNull(types.string),
    productTypeError: types.maybeNull(types.string),
    resultButtonLabel: types.maybeNull(types.string),
    negativeIdCounter: types.optional(types.integer, 1),
    loadingRender: false,

    jobPage: types.optional(types.integer, 1),
    jobEmpty: types.optional(types.boolean, false),
    loadingJobs: types.optional(types.boolean, false)
  })
  .views(views.generateImageStoreViews)
  .actions(actions.generateImageStoreActions)

export default GenerateImageStore